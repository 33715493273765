@use "../../styles/variables" as var;

.payment-screen.screen-container {
    width: 100%;
    overflow: hidden auto;
    background-color: var.$primary-main;

    .header-container {
        padding-bottom: 5%;
    }

    .screen-body-container {
        width: 100%;
        height: 100%;
        overflow: hidden auto;
        margin-top: -20px;
        background-color: var.$white;
        border-radius: 32px 32px 0px 0px;
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        padding: 28px 0px 36px;

        .content-group-wrapper {
            width: 100%;
            padding: 0px 32px 0px;

            .txt-payment {
                width: 100%;
                display: flex;
                justify-content: space-between;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 1px;

                &.using-points {
                    align-items: center;
                    margin-bottom: 24px;
                }

                & > p {
                    margin-bottom: 8px;

                    &.txt-no-points {
                        font-weight: 700;
                        color: var.$secondary-text;
                        margin-bottom: 10px;
                    }
                }


                .txt-points-wrapper {
                    .txt-points {
                        color: var.$secondary-text;
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                        letter-spacing: 1px;

                        & > div {
                            display: inline;
                            margin-left: 6px;
                            font-size: 13px;
                            font-weight: 500;
                        }
                    }
                }

                .txt-total-payment {
                    margin-bottom: 16px;

                    font-family: "Manrope";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 30px;
                    letter-spacing: 1px;

                    &.title {
                        width: 100%;
                        margin-right: 20px;
                        text-align: right;
                    }
                }

                .btn-use-points.my-button {
                    min-width: 100px;
                    width: unset;
                    padding: 7px 22px;

                    span {
                        font-weight: 700;
                        font-size: 12px;
                        line-height: 21px;
                    }
                }

                .img-points {
                    width: 56px;
                    height: 14px;
                    margin-bottom: 3px;
                    margin-left: 3px;
                }
            }

            .rank-fee-header {
                @extend .txt-payment;
                display: flex;
                justify-content: space-between;

                .txt-rank-fee {
                    word-spacing: 10px;
                    letter-spacing: 0px;
                }

                .img-rank {
                    width: 110px;

                    img {
                        width: 100%;
                    }
                }
            }

            .txt-points-balance {
                margin-bottom: 20px;
                font-family: "Manrope";
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 1px;
                color: rgba(0, 0, 0, 0.45);
                padding-left: 4px;
            }
        }

        .divider.ant-divider {
            width: 100%;
            margin: 0 0 16px;
            border-color: #eaeaea;

            // &.payment-method {
            //     margin-bottom: 50px;
            // }
        }

        .rd-using-points-wrapper {
            width: 100%;

            .rd-input-custom-point {
                width: 60px;
                height: 24px;
                margin-right: 8px;

                font-size: 14px;
                line-height: 22px;
            }

            .btn-apply-wrapper {
                width: 100%;
                margin: 20px 0 24px;

                display: flex;
                justify-content: center;
                align-items: center;

                .btn-apply {
                    width: 80px;
                    padding: 7px;

                    span {
                        font-weight: 600;
                        font-size: 13px;
                        line-height: 21px;
                        letter-spacing: 1px;
                    }
                }
            }
        }
        
        .group-btn-payment-methods {
            width: 100%;
            margin-top: 20px;
            // margin: auto 0 0;
            padding: 0;

            .t-money-container{
                width: 100%;
                height: auto;
                border-radius: 5px;
                background-color: #f8f7ff;
                margin: auto 0 10px;
                padding: 10px 10px 1px 10px;
                // margin-bottom: 20px;
            }
            
            .btn-payment-method-wrapper {

                & ~ .btn-payment-method-wrapper {
                    margin-top: 12px;
                }

                .btn-payment-method {
                    border: 1px solid;
                    border-radius: 50px;
                    background: var.$white;
                    box-sizing: border-box;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        font-family: "Manrope";
                        font-style: normal;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                        color: var.$primary-main;
                        margin: 15px 0 15px 8px;
                    }
                }
            }

            .txt-payment-err {
                margin: 8px 0 0 8px;

                text-align: center;
                color: var.$error-text;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .payment-screen.screen-container {
        .screen-body-container {
            margin-top: -20px;
            padding: 36px 50px;

            .content-group-wrapper {
                .rank-fee-header .img-rank {
                    width: 155px;
                }

                .txt-payment {
                    font-size: 22px;
                    line-height: 30px;
                    letter-spacing: 1.5px;

                    &.using-points {
                        margin-bottom: 20px;
                    }

                    .txt-total-payment {
                        font-size: 40px;
                        line-height: 56px;
                        letter-spacing: 1.5px;

                        &.title {
                            margin-right: 30px;
                        }
                    }

                    .txt-points-wrapper {
                        .txt-points {
                            font-size: 36px;
                            line-height: 44px;
                            letter-spacing: 1px;

                            & > div {
                                font-size: 20px;
                            }
                        }
                    }

                    .btn-use-points.my-button {
                        min-width: 125px;
                        width: unset;
                        padding: 13px 26px;

                        .btn-label {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }

                    .img-points {
                        height: 23px;
                        width: 92px;
                    }
                }

                .txt-points-balance {
                    font-size: 18px;
                    line-height: 22px;
                }
            }

            .divider.ant-divider {
                width: 100%;
                margin: 10px 0 30px;
                border-color: #e5e5e5;

                &.payment-method {
                    margin-bottom: 50px;
                }
            }

            .rd-using-points-wrapper {
                .ant-radio-group .ant-space.ant-space-vertical {
                    gap: 12px !important;

                    .ant-radio + span {
                        font-size: 20px;
                        line-height: 30px;
                    }

                    .rd-input-custom-point {
                        width: 90px;
                        height: 36px;
                        font-size: 18px;
                        line-height: 24px;
                    }
                }

                .btn-apply-wrapper {
                    margin: 20px 0;

                    .my-button.btn-apply {
                        min-width: 120px;
                        padding: 13px 30px;

                        span {
                            font-size: 20px;
                            line-height: 26px;
                            letter-spacing: 2px;
                        }
                    }
                }
            }

            .group-btn-payment-methods {
                .btn-payment-method-wrapper {
                    & ~ .btn-payment-method-wrapper {
                        margin-top: 16px;
                    }

                    .btn-payment-method {
                        p {
                            font-size: 22px;
                            line-height: 28px;
                            margin: 18px 0 18px 10px;
                        }
                    }
                }

                .txt-payment-err {
                    margin-top: 5px;

                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 1.5px;
                }
            }
        }
    }
}