@use "../../../styles/variables" as var;

.md-error .ant-modal-content,
.md-error .ant-modal-header {
    border-radius: 20px;
    background: var.$white;

    .md-body-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        .icon-status-wrapper {
            position: absolute;
            top: -55px;
            width: 100px;
            height: 100px;
            border: 2px solid rgb(255, 120, 117, 0.1);
            box-sizing: border-box;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            .icon-status-payment {
                width: 70%;
                height: 70%;

                display: flex;
                align-items: center;
                justify-content: center;

                background-color: var.$white;
                border-radius: 50%;
                box-shadow: 0px 4px 20px rgba(66, 99, 235, 0.2);

                .icon-status {
                    font-size: 30px;
                    color: var.$red;
                }
            }
        }

        .md-body-content {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .md-title {
                margin-top: 30px;
                font-family: "Manrope";
                font-size: 20px;
                font-weight: 800;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: center;
                line-height: 140%;
            }

            .md-message {
                margin: 8px 0 0;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                align-items: center;
                color: var.$gray-text;
                text-align: center;
                letter-spacing: 0.6px;
            }
        }

        .btn-close-wrapper {
            width: 100%;

            .btn {
                width: 100%;
                padding: 15px;
                border-radius: 50px;
                box-sizing: border-box;

                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                &:hover {
                    cursor: pointer;
                }

                p {
                    margin: 0;
                    font-family: 'Manrope';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 26px;
                    text-align: center;
                    color: var.$white;
                }
            }

            .btn-close {
                @extend .btn;
                margin-bottom: 10px;
                background: var.$red;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .md-error.ant-modal .ant-modal-body {
        padding: 24px 30px 30px;

        .md-body-wrapper {
            .md-body-content {
                .md-title {
                    margin-top: 36px;
                    font-size: 24px;
                    line-height: 32px;
                }

                .md-message {
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .btn-close-wrapper {
                .btn-close {
                    padding: 16px;

                    p {
                        font-size: 20px;
                        line-height: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 650px) {
    .md-error.ant-modal {
        margin-top: 60px;
    }
}