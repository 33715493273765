@use "../../../styles/variables" as var;

.btn-register-container {
    background-color: var.$white;
    margin: 27px 0 30px;
    display: flex;
    justify-content: center;

    .button-mess-ok {
        width: 100%;
        height: min-content;
        padding: 15px;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50px;
        background: var.$primary-btn-background;

        &:hover {
            cursor: pointer;
        }

        .btn-register-text {
            margin: 0;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: var.$white;
            letter-spacing: 2px;
        }
    }
}

@media screen and (min-width: 768px) {
    .btn-register-container .button-mess-ok {
        width: 85%;
        padding: 18px;

        .btn-register-text {
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 3px;
        }
    }
}