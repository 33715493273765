@use "../../../styles/variables" as var;
@use "../../../styles/base" as base;

.policy-screen.screen-container {
    .screen-body-wrapper {
        width: 100%;
        height: calc(var(--dynamic-vh, 1vh) * 100 - 16px - 80px);

        .policy-content-wrapper {
            width: 100%;
            margin: 0;
            padding: 0;
            overflow: hidden;

            .object-pdf, iframe, embed {
                width: 100%;
                height: 100%;
                margin: 0;
                padding: 0;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .footer-divider {
        width: 100%;
        height: 1px;
        margin: 0;
        border: none;
        background-color: var.$divider;
    }

    .footer-wrapper {
        width: 100%;
        padding: 15px 32px 20px;

        .chk-agree-policy {
            width: 100%;
            display: flex;
            justify-content: center;

            .ant-checkbox + span {
                color: var.$gray-text;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.6px;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: var.$primary-main;
                border-color: var.$primary-main;
            }

            &:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner {
                border-color: var.$primary-main;
            }
        }

        .my-button {
            margin: 15px 0 0;
            .btn-label {
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .policy-screen.screen-container {
        .screen-body-wrapper {
            height: calc(var(--dynamic-vh, 1vh) * 100 - 16px - 80px);
        }

        .footer-divider {
            height: 2px;
        }
    
        .footer-wrapper {
            padding: 18px 50px 25px;
    
            .chk-agree-policy .ant-checkbox + span {
                margin-left: 4px;
                font-size: 18px;
                line-height: 26px;
            }
    
            .my-button {
                margin-top: 20px;
                
                .btn-label {
                    letter-spacing: 3px;
                }
            }
        }
    }
}