.not-found-screen.screen-container {
    width: 100%;
    height: calc(var(--dynamic-vh, 1vh) * 100);
    margin: 0 auto;
    padding: 30px;

    background-color: white;
}

@media screen and (min-width: 768px) {
    .screen-container {
        padding: 50px;
        font-size: 22px;
    }
}