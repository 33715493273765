@use "../../../styles/variables" as var;

.btn-contact-email {
    width: 100%;
    padding: 15px;
    margin: 10px 0;

    border-radius: 50px;
    background: var.$white;
    border: 1px solid var.$primary-main;
    box-sizing: border-box;

    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: var.$primary-main;

    &:hover {
        cursor: pointer;
    }
}

@media screen and (min-width: 768px) {
    .btn-contact-email {
        font-size: 22px;
        line-height: 150%;
    }
}