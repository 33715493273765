@use "../../../styles/variables" as var;

.selection-screen.screen-container {
    background-color: var.$primary-main;

    .body-wrapper {
        margin-top: 5%;
        width: 100%;
        height: 100%;
        padding: 36px 32px 16px;

        background-color: var.$white;
        border-radius: 32px 32px 0px 0px;
        align-items: center;
        display: flex;
        flex-direction: column;

        .message {
            width: 100%;
            margin-bottom: 18px;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 1px;
            color: rgba(0, 0, 0, 0.85);
        }

        .txt-err {
            width: 100%;
            margin: 16px 0;

            color: var.$error-text;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .all-selections-wrapper {
            .ant-radio-wrapper {
                width: 100%;
                margin: 0;

                &~.ant-radio-wrapper {
                    margin-top: 24px;
                }

                .ant-radio+span {
                    width: 100%;

                    .option-wrapper {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        .img-card-rank {
                            width: 60%;
                            max-height: 60px;
                            max-width: 120px;
                        }

                        .txt-admission-fee {
                            width: 100%;
                            margin: 0 0 0 12px;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 24px;
                            color: var.$primary-text;
                        }
                    }

                }
            }
        }

        .btn-confirm-wrapper {
            width: 100%;
            margin: auto 0 20px;

            .my-button .btn-label {
                font-weight: 600;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .selection-screen.screen-container {
        .body-wrapper {
            padding: 36px 50px 16px;

            .message {
                font-size: 20px;
                line-height: 28px;
                margin-bottom: 22px;
            }

            .txt-err {
                margin: 8px 0;
                font-size: 18px;
                line-height: 26px;
            }
    
            .all-selections-wrapper {
                width: 95%;

                .ant-radio-wrapper {
                    width: 100%;
                    margin: 0;
    
                    & ~ .ant-radio-wrapper {
                        margin-top: 30px;
                    }
    
                    .ant-radio + span {
                        width: 100%;
                        padding: 0 16px;
    
                        .option-wrapper {    
                            .img-card-rank {
                                max-height: 80px;
                                max-width: 150px;
                            }
    
                            .txt-admission-fee {
                                margin: 0 0 0 16px;
                                font-size: 18px;
                                line-height: 26px;
                            }
                        }
    
                    }
                }
            }
    
            .btn-confirm-wrapper {
                width: 100%;
                margin: auto 0 20px;
    
                .my-button {
                    padding: 20px;

                    .btn-label {
                        letter-spacing: 3px;
                    }
                }
            }
        }
    }
}

.introduce-code {
    width: 100%;
    margin-top: 60px;

    .introduce-code-message {
        font-weight: bold;
        margin-bottom: 10px;
    }
}