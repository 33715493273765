@use '../../../styles/variables' as var;

.my-button {
    width: 100%;
    height: min-content;
    padding: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50px;
    background: var.$primary-btn-background;

    &:hover {
        cursor: pointer;
    }

    .btn-label {
        margin: 0;
        font-family: "Manrope";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: var.$white;
        letter-spacing: 1px;
    }
}

@media screen and (min-width: 768px) {
    .my-button {
        padding: 18px;

        .btn-label {
            font-size: 22px;
            line-height: 33px;
        }
    }
}