@use "../../../styles/variables" as var;
@use "../../../styles/base" as base;

.md-confirm-exit-rank.ant-modal {
    width: 100% !important;
    max-width: 95%;
}

.md-confirm-exit-rank .ant-modal-content,
.md-confirm-exit-rank .ant-modal-header {
    border-radius: 20px;
    background: var.$white;

    .md-body-wrapper {
        width: 100%;

        .icon-status-confirm-wrapper {
            position: absolute;
            top: -55px;
            left: 50%;
            transform: translateX(-50%);

            width: 100px;
            height: 100px;
            border: 2px solid rgba(79, 195, 247, 0.1);
            box-sizing: border-box;
            border-radius: 50%;
            justify-content: center;
            align-items: center;
            display: flex;

            .icon-status-confirm {
                justify-content: center;
                align-items: center;
                display: flex;
                width: 70%;
                height: 70%;
                background-color: var.$white;
                border-radius: 50%;
                box-shadow: 0px 4px 20px rgba(66, 99, 235, 0.2);

                .icon-status {
                    font-size: 30px;
                    color: var.$primary-main;
                }
            }
        }

        .md-body-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn-close-icon-times {
                position: absolute;
                left: 20px;
                top: 60px;
                font-size: 17px;
                color: var.$primary-main;

                &:hover {
                    cursor: pointer;
                }
            }

            .md-title {
                margin-top: 30px;
                font-family: "Manrope";
                font-size: 20px;
                font-weight: 800;
                line-height: 140%;
                letter-spacing: 0px;
                text-align: center;
            }

            .md-message-warning {
                width: 100%;
                margin: 0;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: -0.4px;
                text-align: left;
                line-height: 24px;
                display: flex;
                align-items: center;
                color: var.$gray-text;
            }

            .list-msg {
                list-style: none;
                width: 98%;
                margin: 0;
                padding-left: 6px;

                li {
                    width: 100%;
                    margin: 0.4rem 0;
                    display: flex;

                    & > p {
                        display: inline-block;
                    }
                }

                li::before {
                    content: "\2022";
                    color: var.$gray-text;
                    font-weight: bold;
                    display: inline-block;
                    width: 0.8em;
                    font-size: 1.1rem;
                }
            }

            .chk-agree-out-rank {
                width: 100%;
                margin-top: 20px;
                display: flex;
                justify-content: center;

                .ant-checkbox-checked .ant-checkbox-inner {
                    background-color: var.$primary-main;
                    border-color: var.$primary-main;
                }

                &:hover .ant-checkbox-inner,
                .ant-checkbox:hover .ant-checkbox-inner {
                    border-color: var.$primary-main;
                }
            }

            .account-info {
                width: 100%;
                margin: 0;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 700;
                font-size: 17px;

                color: #000000;
                text-align: center;
                line-height: 26px;
                letter-spacing: -0.408px;

                display: flex;
                justify-content: center;

                b {
                    display: block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .md-message-center {
                width: 95%;
                min-height: calc(3 * 24px);
                margin: 12px 0;
                // max-width: 210px;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;

                text-align: center;
                color: var.$gray-text;
                line-height: 24px;
                letter-spacing: -0.4px;

                display: flex;
                align-items: flex-start;
                justify-content: center;
            }

            .md-iframe-wrapper {
                width: calc(100% + 16px * 2);
                height: 45vh;
                overflow: auto hidden;

                .md-iframe {
                    width: 136%;
                    min-height: 100%;
                }

                @include base.customScrollbar;
            }
        }

        .btn-group-container {
            margin-top: 24px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 12px;

            .btn-cancel {
                width: 100%;
                padding: 15px;
                margin-right: 14px;

                border-radius: 50px;
                background: var.$white;
                border: 1px solid var.$primary-main;
                box-sizing: border-box;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: var.$primary-main;

                &:hover {
                    cursor: pointer;
                }
            }

            .btn-continue {
                width: 100%;
                padding: 15px;
                border-radius: 50px;
                background: var.$red;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: var.$white;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .btn-close.my-button {
            margin-top: 20px;

            span {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
            }

            &.failure {
                margin: 0;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .md-confirm-exit-rank.ant-modal {
        max-width: calc(0.75 * 768px);

        .ant-modal-content {
            .ant-modal-body {
                padding: 24px 50px 36px;

                .md-body-wrapper {
                    .md-body-content {
                        .btn-close-icon-times {
                            position: absolute;
                            left: 36px;
                            top: 64px;
                            font-size: 30px;
                        }

                        .md-title {
                            margin-top: 36px;
                            font-size: 24px;
                            line-height: 32px;
                        }

                        .md-message-warning {
                            font-size: 20px;
                            line-height: 36px;
                        }

                        .list-msg {
                            width: 100%;
                            padding-left: 10px;

                            li::before {
                                width: 1.2em;
                            }
                        }

                        .chk-agree-out-rank {
                            .ant-checkbox + span {
                                font-size: 18px;
                            }
                        }

                        .account-info {
                            font-size: 20px;
                            line-height: 30px;
                            letter-spacing: 0;
                        }

                        .md-message-center {
                            width: 100%;
                            min-height: calc(3 * 30px);
                            margin-top: 24px;

                            font-size: 20px;
                            line-height: 30px;
                        }

                        .md-iframe-wrapper {
                            width: calc(100% + 36px * 2);
                            height: 400px;
                            overflow: auto hidden;

                            .md-iframe {
                                width: 110%;
                                min-height: 100%;
                            }
                        }
                    }

                    .btn-group-container {
                        column-gap: 20px;

                        .btn-cancel,
                        .btn-continue {
                            padding: 18px;

                            font-size: 22px;
                            line-height: 30px;
                        }

                        .btn-continue {
                            letter-spacing: 3px;
                        }
                    }

                    .btn-close.my-button {
                        margin-top: 20px;
                        padding: 18px;

                        span {
                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 812px) {
    .md-confirm-exit-rank.ant-modal {
        margin-top: 40px;

        & .ant-modal-content {
            .md-body-wrapper {
                .md-body-content {
                    .md-iframe-wrapper {
                        height: 40vh;
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 667px) {
    .md-confirm-exit-rank.ant-modal {
        margin-top: 60px;

        & .ant-modal-content {
            .md-body-wrapper {
                .md-body-content {
                    .md-iframe-wrapper {
                        height: 35vh;
                    }
                }
            }
        }
    }
}