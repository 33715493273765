@mixin customScrollbar {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
        -webkit-box-shadow: inset 0 0 6px rgba(165, 108, 108, 0.2);
        background-color: #d1d1d1;
    }
}

@mixin customHideScrollbar {
    &::-webkit-scrollbar {
        display: none;
    }
}