@use "../../../styles/variables" as var;

.md-warning-rejoin-rank.ant-modal {
    width: 100% !important;
    max-width: 95%;
}

.md-warning-rejoin-rank .ant-modal-content,
.md-warning-rejoin-rank .ant-modal-header {
    border-radius: 20px;
    background: var.$white;

    .md-body-wrapper {
        width: 100%;

        .icon-status-confirm-wrapper {
            width: 100px;
            height: 100px;

            position: absolute;
            top: -55px;
            left: 50%;
            transform: translateX(-50%);

            border: 2px solid rgba(79, 195, 247, 0.1);
            box-sizing: border-box;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            .icon-status-confirm {
                width: 70%;
                height: 70%;

                background-color: var.$white;
                border-radius: 50%;
                box-shadow: 0px 4px 20px rgba(66, 99, 235, 0.2);

                display: flex;
                align-items: center;
                justify-content: center;

                .icon-status {
                    font-size: 30px;
                    color: var.$primary-main;
                }
            }
        }

        .md-body-content {
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;

            .md-title {
                margin-top: 30px;
                font-family: "Manrope";
                font-size: 20px;
                font-weight: 800;
                line-height: 28px;
                letter-spacing: 0px;
                text-align: center;
            }

            .md-message-warning {
                margin: 0;
                display: flex;
                align-items: center;

                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.4px;
                text-align: left;
                color: var.$gray-text;
            }
        }

        .btn-group-container {
            margin-top: 18px;

            .btn-exit {
                width: 100%;
                padding: 15px;

                border-radius: 50px;
                background: var.$red;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: "Manrope";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: var.$white;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .md-warning-rejoin-rank.ant-modal {
        max-width: calc(0.75 * 768px);

        .ant-modal-content .ant-modal-body {
            padding: 24px 50px 36px;

            .md-body-wrapper {
                .md-body-content {
                    .md-title {
                        margin-top: 36px;
                        font-size: 24px;
                        line-height: 32px;
                    }

                    .md-message-warning {
                        font-size: 18px;
                        line-height: 28px;
                    }
                }

                .btn-group-container {
                    margin-top: 24px;

                    & > .btn-contact-email {
                        margin: 16px 0;
                    }

                    .btn-exit {
                        padding: 16px;
                        font-size: 22px;
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 650px) {
    .md-warning-rejoin-rank.ant-modal {
        margin-top: 60px;
    }
}