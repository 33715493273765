@use "../../styles/variables" as var;

.header-container {
    width: 100%;
    padding-top: 16px;

    .header-wrapper {
        width: 100%;
        height: 80px;
        margin: 0;
    
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
    
        .hd-screen-title {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 800;
            line-height: 140%;
            font-size: 20px;
            color: var.$primary-text;
            margin-bottom: 0;
        }
    
        .hd-btn-back {
            position: absolute;
            top: 50%;
            left: 32px;
            transform: translateY(-50%);
            font-size: 24px;
            color: var.$primary-text;
        }
    }
}

@media screen and (min-width: 768px) {
    .header-container {
        width: unset;

        .header-wrapper {
            .hd-screen-title {
                font-size: 24px;
            }
            .hd-btn-back {
                font-size: 30px;
                left: 50px;
            }
        }
    }
}