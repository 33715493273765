@use "../../../styles/variables" as var;

.payment-success-screen.screen-container {
    overflow: hidden auto;

    display: flex;
    flex-flow: column;

    .screen-body-wrapper {
        width: 100%;
        padding: 12% 18px 20px;

        border-radius: 0px 0px 48px 48px;
        background-color: var.$primary-main;

        .result-frame {
            width: 100%;
            height: 100%;
            padding: 24px;

            border-radius: 40px;
            background: var.$white;
            justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;

            position: relative;

            .icon-status-container {
                position: absolute;
                top: -50px;
                width: 100px;
                height: 100px;
                box-sizing: border-box;
                border: var.$border-success;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                display: flex;

                .icon-status-circle {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    width: 70%;
                    height: 70%;
                    box-shadow: var.$box-shadow;
                    background-color: var.$white;
                    border-radius: 50%;

                    .icon-status {
                        font-size: 30px;
                        color: var.$primary-main;
                    }
                }

                .star-filled {
                    position: absolute;
                    bottom: 10px;
                    right: -20px;
                }

                .star-bland-top {
                    position: absolute;
                    top: 10px;
                    right: -23px;
                }

                .star-bland-bottom {
                    position: absolute;
                    bottom: -4px;
                    left: -20px;
                }
            }

            .result-content {
                width: 100%;
                padding-top: 35px;

                .msg-completed {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 150%;

                    text-align: center;
                    color: var.$gray-text-light;

                    span {
                        font-weight: 900;
                    }
                }

                .background-card-rank {
                    margin-top: 16px;
                    margin-bottom: 50px;
                }

                .divider {
                    margin: 0;

                    &:last-child {
                        margin-bottom: 4px;
                    }
                }

                .txt-content {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;

                    p {
                        margin: 13px 0;
                        letter-spacing: 1px;
                        font-weight: 500;
                    }

                    .text_title_info_rank {
                        font-size: 16px;
                        line-height: 160%;
                        font-weight: 400;
                        color: var.$gray-text-light;

                        & + p > span {
                            font-weight: 600;
                        }
                    }

                    .text_value_info_rank {
                        font-weight: 500;
                        font-size: 14px;
                        color: var.$gray-text-light;
                        color: var.$primary-text;
                    }
                }
            }
        }
    }

    .screen-footer-wrapper {
        width: 100%;
        margin-top: auto;

        display: flex;
        align-items: flex-end;
        padding: 36px 32px;

        .my-button .btn-label {
            font-weight: 600;
        }
    }
}

@media screen and (min-width: 768px) {
    .payment-success-screen.screen-container {
        .screen-body-wrapper {
            width: 100%;
            padding: 8% 30px 32px;

            .result-frame {
                padding: 40px 32px 32px;

                .result-content {
                    .msg-completed {
                        font-size: 18px;
                    }

                    .txt-content {
                        p, .text_title_info_rank {
                            margin: 16px 0;
                            font-size: 22px;
                        }

                        .text_value_info_rank {
                            font-size: 22px;
                        }
                    }
                }
            }
        }

        .screen-footer-wrapper {
            padding: 36px 50px;

            .my-button {
                padding: 20px;

                .btn-label {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }
    }
}