@use "../../styles/variables" as var;
@use "../../styles/base" as base;

.home-screen.screen-container {
    padding: 0 0 16px;
    display: flex;
    flex-flow: column nowrap;

    .body-wrapper {
        margin: 0;
        padding: 0;
        height: calc(var(--dynamic-vh, 1vh) * 100 - 96px);
        overflow-y: auto;
        // @include base.customHideScrollbar;

        display: flex;
        flex-flow: column nowrap;

        .user-info {
            width: 100%;
            padding: 0 32px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;

            color: var.$primary-text;

            .user-name {
                max-width: calc(100% - var(--user-id-width) - 30px);
                // max-height: calc(20.5px * 1.5 * 2 + 3px);

                font-weight: 500;
                font-size: 20.5px;
                line-height: 150%;

                position: relative;
                opacity: 1;
                
                display: inline-block;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;

                span {
                    font-weight: 400;
                    margin-left: 2px;
                }

                &.is-overflow::after {
                    display: block;
                    content: "...様";
                    font-weight: 400;

                    position: absolute;
                    bottom: 0;
                    right: 0;
                    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 0%);
                    padding-left: 1px;
                }
            }

            .user-id {
                min-width: 110px;
                width: fit-content;
                margin-top: 4px;
                margin-left: 12px;

                font-weight: 500;
                font-size: 16px;
                line-height: 160%;
            }
        }

        .main-info-wrapper {
            padding: 0 32px;
        }

        .img-rank {
            width: 100%;
            margin-bottom: 20px;

            img {
                width: 100%;
            }
        }

        .txt-err {
            width: 100%;
            margin: 16px 0;
            text-align: center;

            color: var.$error-text;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
        }

        .txt-msg-register-rank {
            width: 100%;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            text-transform: uppercase;
            color: var.$primary-text;

            display: flex;
            flex-flow: column;
            align-items: center;
        }

        .list-rank-imgs {
            margin: 24px 0 0;

            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 16px;

            img {
                width: 100%;
            }
        }

        .expiration-warning-wrapper {
            width: 100%;
            margin: 0;
            display: flex;
            flex-flow: row nowrap;

            .icon-warning {
                margin-top: 4.5%;
                margin-right: 6px;
                font-size: 28px;
                color: #FAAD14;
            }

            p {
                margin: 0;
                font-family: "Manrope";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 160%;

                &.txt-exp-date {
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: rgba(0, 0, 0, 0.45);
                }
            }
        }
    }

    .btn-register-wrapper {
        margin: auto 0 36px;
        padding-top: 44px;

        .my-button .btn-label {
            font-weight: 500;
        }
    }

    .custom-btn-text {
        font-weight: 600;
        letter-spacing: 0;
    }

    .group-btn-rank-actions {
        // height: 100%;
        margin: 50px 0 36px;

        display: flex;
        flex-flow: column nowrap;

        .btn-renewal-rank {
            width: 100%;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;

            & .my-button {
                width: fit-content;
                padding: 15px 36px;

                &>span {
                    @extend .custom-btn-text;
                }

                &.btn-expired-disable {
                    border: 1px solid var.$gray-disable;
                    background: transparent;

                    &>span {
                        color: var.$gray-disable;
                    }
                }
            }
        }

        .btn-change-rank {
            width: 100%;
            margin: 0 0 16px;
            display: flex;
            justify-content: center;

            & .my-button {
                width: fit-content;
                padding: 15px 36px;
                background: var.$secondary-btn-background;

                &>span {
                    @extend .custom-btn-text;
                }
            }
        }

        .btn-out-rank {
            width: 100%;
            display: flex;
            justify-content: center;

            & .my-button {
                width: fit-content;
                padding: 15px 60px;
                background: var.$btn-out-rank-background;

                &>span {
                    @extend .custom-btn-text;
                }
            }
        }

        .grp_btn_rows {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .btn_member_info {
                margin: 6px;
                height: 56px;
                width: 150px;
                border-radius: 16px;
                background: var.$primary-main;
                border-radius: 16px;
                display: flex;
                justify-content: center;
                align-items: center;

                font-family: "Manrope";
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 160%;
                text-align: center;
                color: var.$white;
            }
        }

        .btn_warning_home_screen {
            margin-top: 6px;
            height: 52px;
            width: 312px;
            border-radius: 16px;
            background: var.$white;
            border: 1px solid #d9d9d9;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            color: #d9d9d9;
        }
    }

    .footer-container {
        margin-top: auto;

        .footer-divider {
            width: 100%;
            height: 1px;
            margin: 0;
            border: none;
            background-color: var.$divider;
        }

        .contact-zone-wrapper {
            width: 100%;
            padding: 12px 28px 30px;

            h4 {
                margin-bottom: 10px;

                color: var.$primary-main;
                text-align: center;

                font-family: 'Manrope';
                font-weight: 700;
                font-size: 16px;
                line-height: 25.6px;
            }

            .btn-group {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 8px;

                & > .btn-call {
                    height: min-content;
                    padding: 6px;
                    line-height: 22px;

                    span {
                        font-size: 11px;
                    }
                }

                & > .btn-contact-email {
                    margin: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .home-screen.screen-container {
        .body-wrapper .main-info-wrapper {
            .expiration-warning-wrapper + .group-btn-rank-actions {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-height: 600px) {
    .home-screen.screen-container {
        .body-wrapper {
            .user-info {
                .user-name {
                    font-size: 18px;
                    max-height: calc(18px * 1.5 * 2);
                }

                .user-id {
                    margin-top: 2px;
                }
            }

            .group-btn-rank-actions {
                margin-top: 30px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .home-screen.screen-container {
        .body-wrapper {
            .user-info, .main-info-wrapper {
                padding: 0 50px;
            }

            .user-info {
                .user-name {
                    font-size: 24px;
                    // max-height: calc(24px * 1.5 * 2 + 3px);
                    max-width: calc(100% - var(--user-id-width) - 100px);
                }

                .user-id {
                    font-size: 20px;
                }
            }

            .main-info-wrapper {
                .txt-err {
                    font-size: 18px;
                    line-height: 28px;
                    letter-spacing: 1px;
                }

                .list-rank-imgs {
                    column-gap: 30px;

                    img {
                        width: 100%;
                    }
                }

                .expiration-warning-wrapper {
                    .icon-warning {
                        margin-top: 2.5%;
                        margin-right: 12px;
                        font-size: 40px;
                        color: #FAAD14;
                    }

                    p {
                        font-size: 18px;
                        letter-spacing: 1px;

                        &.txt-exp-date {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }

                .group-btn-rank-actions {
                    margin-top: 80px;

                    .btn-renewal-rank,
                    .btn-change-rank {
                        margin-bottom: 20px;

                        & .my-button {
                            width: fit-content;
                            padding: 18px 68px;
                        }
                    }

                    .btn-out-rank .my-button {
                        padding: 18px 100px;
                    }
                }
            }
        }

        .footer-container {
            .contact-zone-wrapper {
                padding: 16px 48px 30px;

                h4 {
                    margin-bottom: 16px;

                    font-size: 20px;
                    line-height: 32px;
                }

                .btn-group {
                    column-gap: 16px;

                    & > .btn-call {
                        // height: min-content;
                        padding: 10px 15px;
                        line-height: 28px;

                        span {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 768px) and (max-height: 1100px) {
    .home-screen.screen-container {
        .body-wrapper {
            .main-info-wrapper {
                .expiration-warning-wrapper + .group-btn-rank-actions {
                    margin-top: 36px;
                }
            }
        }
    }
}

@media screen and (min-width: 1080px) {
    .home-screen.screen-container {
        .body-wrapper {
            .user-info, .main-info-wrapper {
                padding: 0 50px;
            }

            .user-info {
                .user-name {
                    max-width: calc(100% - var(--user-id-width) - 85px);
                }
            }
        }
    }
}