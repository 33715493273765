@use "../../../styles/variables" as var;

.btn-call {
    width: 100%;
    padding: 6px 15px;

    border-radius: 50px;
    background: var.$white;
    border: 1px solid var.$primary-main;
    box-sizing: border-box;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;

    font-family: "Manrope";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: var.$primary-main;

    span {
        font-size: 12px;
    }

    &:hover,
    &:focus,
    &:active {
        cursor: pointer;
        color: var.$primary-main;
    }
}

@media screen and (min-width: 768px) {
    .btn-call {
        padding: 8px 15px 6px;

        font-size: 21px;
        line-height: 140%;

        span {
            font-size: 15px !important;
        }
    }
}