.error-screen {
    width: 100%;
    height: calc(var(--dynamic-vh, 1vh) * 100);
    margin: 0;
    padding: 36px;

    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 768px) {
    .error-screen {
        font-size: 24px;
    }
}