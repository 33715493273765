@use "../../styles/variables" as var;
@use "../../styles/base" as base;

.screen-container {
    width: 100%;
    height: calc(var(--dynamic-vh, 1vh) * 100);
    padding: 0;
    // border: 0.5px solid var.$gray-text;
    background-color: var.$white;

    .page-body-container {
        height: calc(var(--dynamic-vh, 1vh) * 100 - var(--page-body-offset-top));
        // @include base.customHideScrollbar;

        .ant-spin-nested-loading {
            height: 100%;

            .ant-spin-container {
                height: 100%;
            }

            .loading-spinner.ant-spin.ant-spin-spinning {
                height: 100%;
                color: var.$primary-main;
            }
        }
    }
}