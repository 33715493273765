html {
    /* overflow: hidden; */
    width: 100%;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;

    /* position: fixed; */
    /* prevent overscroll bounce*/
    /* overflow-y: hidden;
    -webkit-overflow-scrolling: touch; */
    /* iOS velocity scrolling */

    font-family: "Manrope", "SF Pro Display", -apple-system, BlinkMacSystemFont,
        "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    display: flex;
    justify-content: center;
    background-color: white;
}

/* Manrope - Variable all fonts */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-VariableFont_wght.ttf) format('truetype');
}

/* Manrope - Regular 400 */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-Regular.ttf) format('truetype');
    font-weight: 400;
}

/* Manrope - Medium 500 */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-Medium.ttf) format('truetype');
    font-weight: 500;
}

/* Manrope - Semi-bold 600 */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

/* Manrope - Bold 700 */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-Bold.ttf) format('truetype');
    font-weight: 700;
}

/* Manrope - Extra-bold 800 */
@font-face {
    font-family: 'Manrope';
    src: local('Manrope'),
        url(./assets/font/Manrope-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

/* SFProText - Medium 500 */
@font-face {
    font-family: 'SFProText';
    font-style: normal;
    font-weight: 500;
    src: local('SFProText'),
        url(./assets/font/SF-Pro-Text-Medium.otf) format('opentype');
}


/* SF Pro Displa - Bold 700 */
@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'),
        url(./assets/font/SFProDisplay-Bold.ttf) format('truetype');
    font-weight: 700;
}

@media screen and (min-width: 1080px) {
    body {
        background-color: #000 !important;
    }

    .screen-container {
        max-width: 1080px;
    }
}