@use "../../../styles/variables" as var;

.md-check-credit-card.ant-modal {
    width: 100% !important;
    max-width: 95%;
}

.md-check-credit-card .ant-modal-content,
.md-check-credit-card .ant-modal-header {
    border-radius: 32px;
    background: var.$white;
    height: fit-content;

    .ant-modal-body {
        padding: 24px;
    }

    .md-body-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .md-body-content {
            width: 100%;

            .modal-title {
                font-family: "Manrope";
                font-style: normal;
                font-size: 20px;
                font-weight: 800;
                line-height: 28px;
                text-align: center;
                margin-bottom: 16px;
            }

            .md-message-content {
                width: 100%;
                margin-bottom: 22px;
                text-align: center;

                // &:nth-child(2),
                // &:nth-child(3),
                &:last-child {
                    margin-bottom: 12px;
                }

                font-family: 'SFProText';
                font-weight: 500;
                font-size: 17px;
                line-height: 26px;

                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .checkbox-not-show-next-time {
            .ant-checkbox + span {
                color: var.$gray-text;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.4px;
            }

            .ant-checkbox-checked .ant-checkbox-inner {
                background-color: var.$primary-main;
                border-color: var.$primary-main;
            }

            &:hover .ant-checkbox-inner,
            .ant-checkbox:hover .ant-checkbox-inner {
                border-color: var.$primary-main;
            }
        }

        .btn-payment-method {
            width: 100%;
            margin-top: 16px;
            padding: 15px;

            border: none;
            border-radius: 50px;
            background: var.$primary-main;

            display: flex;
            align-items: center;
            justify-content: center;

            font-family: "Manrope";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 160%;
            text-align: center;
            color: var.$white;

            & + .btn-payment-method {
                margin-top: 14px;
                background: var.$red;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .md-check-credit-card.ant-modal {
        max-width: 500px;

        .ant-modal-content {
            .ant-modal-body {
                padding: 40px;

                .md-body-wrapper {
                    .md-body-content {            
                        .modal-title {
                            font-size: 24px;
                            line-height: 32px;
                            margin-bottom: 30px;
                        }
            
                        .md-message-content {
                            &:last-child {
                                margin-bottom: 22px;
                            }

                            font-size: 20px;
                            line-height: 30px;
                        }
                    }
            
                    .checkbox-not-show-next-time {
                        .ant-checkbox+span {
                            color: var.$gray-text;
                            font-size: 18px;
                            line-height: 26px;
                            letter-spacing: unset;
                        }
                    }
            
                    .btn-payment-method {
                        padding: 18px;
                        font-size: 21px;
                        line-height: 32px;
                    }
                }
            }
        }
    }
}