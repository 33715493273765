.contact-screen.screen-container {
    width: 100%;

    iframe.iframe-wrapper {
        width: 100%;
        height: calc(var(--dynamic-vh, 1vh) * 100 - 16px - 80px);
        // height: 100%;
        margin: 0;
        padding: 0;

        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
    }
}