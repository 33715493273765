@use "../../styles/variables" as var;
@use "../../styles/base" as base;

$char-w: 2.8ch;
$gap: 0.3 * $char-w;
$n-char: 6;
$in-w: $n-char * ($char-w + $gap);

.register-screen.screen-container {
    padding: 16px 32px;

    .ant-spin-container {
        display: flex;
        flex-flow: column nowrap;
    }

    .item-content-header {
        width: 100%;
        height: 80px;
        margin: 0;

        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .screen-header {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 800;
            font-size: 21px;
            color: var.$primary-text;
            margin-bottom: 0;
        }

        .header-button-back {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            font-size: 24px;
            color: var.$primary-text;
        }
    }

    .body-wrapper {
        height: calc(100% - 80px);
    }

    .item-content-body {
        width: 100%;
        color: var.$white;

        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;

        .text-title-screen {
            max-width: 285px;
            min-height: 66px;
            margin: 50px 0 14px;

            font-family: 'SF Pro Display';
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.3px;
            color: var.$primary-text;

            &.screen-phone-number {
                max-width: unset;
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        .text-err-msg {
            width: 90%;
            min-height: 42px;
            margin-bottom: 20px;

            color: var.$red;
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;

            display: flex;
            justify-content: center;
            align-items: flex-end;
            text-align: center;
        }

        .input-phone-number-auth {
            width: 100%;
            margin-top: 8px;
            padding: 12px 16px;
            background: #f5f5f5;
            border: none;
            border-radius: 16px;

            input {
                background: #f5f5f5;
                margin-left: 10px;
                font-size: 18px;
            }
        }

        .input-sms-wrapper {
            width: 100%;
            margin-bottom: 20px;
            padding: 0 4px;

            display: grid;
            grid-template-columns: repeat(6, 1fr);
            column-gap: 16px;

            .input-sms-code {
                min-width: 22px;
                margin: 0;
                padding: 0 0 4px;
                
                border-bottom: 2px solid var.$divider;
                // background: repeating-linear-gradient(90deg, var.$divider 0, var.$divider $char-w,
                //     transparent 0, transparent $char-w + $gap) 0 100% / #{$in-w - $gap} 2px no-repeat;

                text-align: center;
                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
            }


            .input-number-auth-sms-code {
                width: $in-w;
                margin: 0 0 18px 4px;
                padding: 0 0 4px 12px;
                overflow: hidden;

                border: none;
                // @include input-sms-code(
                //   $char-w: 2.8ch,
                //   // $gap: 0.3 * $char-w,
                //   $letter-spacing: 6px,
                //   'width', 'background', 'letter-spacing'
                // );

                // background: repeating-linear-gradient(90deg, var.$divider 0, var.$divider $char-w,
                //     transparent 0, transparent $char-w + $gap) 0 100%/ #{$in-w - $gap} 2px no-repeat;
                // letter-spacing: calc($gap + $char-w/2 + 6px);

                font-weight: 700;
                font-size: 24px;
                line-height: 36px;
                // font: 4.8ch droid sans mono, consolas, monospace;

                &:focus {
                    outline: none;
                    color: var.$primary-text;
                }
            }
        }
    }

    .container-footers {
        widows: 100%;
        min-height: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .text-message-regis {
            font-family: "Manrope";
            font-style: normal;
            font-weight: 400;
            font-size: 0.85rem;
            line-height: 21px;
            color: var.$gray-text;

            p {
                margin-bottom: 15px;
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .register-screen.screen-container {
        padding: 16px 50px;

        .item-content-header {
            .screen-header {
                font-size: 24px;
            }

            .header-button-back {
                font-size: 30px;
            }
        }

        .item-content-body {
            .text-title-screen {
                max-width: 90%;
                min-height: 66px;
                margin: 100px 0 14px;

                font-size: 26px;
                line-height: 150%;
                letter-spacing: unset;

                &.screen-phone-number {
                    max-width: 70%;
                    font-size: 26px;
                    margin-bottom: 20px;
                }
            }

            .text-err-msg {
                width: 90%;
                min-height: 42px;
                margin-bottom: 20px;

                font-size: 18px;
                line-height: 27px;
            }

            .input-phone-number-auth {
                width: 85%;
                height: 60px;
                margin-top: 8px;
                padding: 12px 16px;

                input {
                    background: #f5f5f5;
                    margin-left: 10px;
                    font-size: 24px;
                }

                .ant-input-prefix svg {
                    width: 24px;
                    height: 24px;
                    margin-top: 3px;
                }

                .ant-input-suffix .ant-input-clear-icon {
                    padding-top: 3px;

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }

            .input-sms-wrapper {
                width: 80%;
                margin-bottom: 20px;
                padding: 0;

                column-gap: 24px;

                .input-sms-code {
                    font-size: 32px;
                    line-height: 48px;
                    border-bottom-width: 3px;
                }
            }
        }

        .container-footers {
            width: 88%;
            min-height: 100px;
            margin: 0 auto;

            .text-message-regis {
                margin-top: 12px;

                font-size: 17px;
                line-height: 21px;
                color: var.$gray-text;
            }
        }
    }
}