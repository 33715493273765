// colors
$primary-main: #5EBBD8;

$primary-text: #15141F;
$primary-text-light: rgba(0, 0, 0, 0.85);
$secondary-text: #FA8C16;
$gray-text: #868686;
$gray-text-light: #A2A0A8;
$gray-disable: #C3C6C6;
$white: #ffffff;
$red: #FF7875;
$error-text: #FF4D4F;

$box-shadow: 0px 4px 20px rgba(66, 99, 235, 0.2);
$border-success: 2px solid rgb(66, 99, 235, 0.1);
$border-error: 2px solid rgb(255, 120, 117, 0.1);

$primary-btn-background: linear-gradient(280.27deg, #13A5D2 7.14%, #61BFDC 45.28%), #6AC4EB;
$secondary-btn-background: linear-gradient(77.97deg, #F8671E 4.67%, #FB7E1D 53.96%), #FF9822;
$btn-out-rank-background: linear-gradient(77.97deg, #A2A3A4 4.67%, rgba(220, 220, 220, 0) 53.96%), #C3C6C6;

$divider: #D9D9D9;